import Loading from 'components/Loading';
import moment from 'moment';
import {useEffect, useMemo} from 'react';
import {connect} from 'react-redux';
import {
  fetchTermsRequest,
  getTerms,
  getTermsRequestStatus,
} from 'store/reducers/terms';
import {ApplicationState} from 'store/rootReducer';
import {Term} from 'store/services/termsServices';
import {StoreStatus} from 'utils/types/store';
import styles from './Privacy.module.scss';

interface StateProps {
  terms: Term[];
  termsRequestStatus: StoreStatus;
}

interface DispatchProps {
  fetchTermsRequest: typeof fetchTermsRequest;
}

type PrivacyProps = StateProps & DispatchProps;

function Privacy({
  terms,
  termsRequestStatus,
  fetchTermsRequest,
}: PrivacyProps): JSX.Element {
  useEffect(() => {
    fetchTermsRequest();
  }, []);

  const {privacyText, privacyDate} = useMemo(() => {
    const privacyTerm = terms?.find(
      (term) => term.parameter === 'terms_privacy_text',
    );

    return {
      privacyText: privacyTerm?.value || '',
      privacyDate: privacyTerm?.modified || '',
    };
  }, [terms]);

  return (
    <div className={styles.privacyWrapper}>
      <h3>POLÍTICA DE PRIVACIDADE</h3>

      {termsRequestStatus?.loading ? (
        <Loading show />
      ) : (
        <div className={styles.privacyBody}>
          <p>
            Fonte:{' '}
            <a href="/privacidade" target="_blank">
              www.professor.letrus.com.br/privacidade/
            </a>
          </p>
          <p>Última atualização: {moment(privacyDate).format('DD/MM/YYYY')}</p>
          <div dangerouslySetInnerHTML={{__html: privacyText}} />
        </div>
      )}
    </div>
  );
}

export default connect(
  (state: ApplicationState) => ({
    terms: getTerms(state),
    termsRequestStatus: getTermsRequestStatus(state),
  }),
  {
    fetchTermsRequest,
  },
)(Privacy);
