import {fromJS, Map} from 'immutable';
import {Reducer} from 'redux';
import {call, put, StrictEffect} from 'redux-saga/effects';
import {createSelector} from 'reselect';
import {ApplicationState} from 'store/rootReducer';
import {fetchServerStatusService} from 'store/services/globalConfigurationServices';
import {action} from 'typesafe-actions';

// Action types
export enum LetrusGlobalConfigurationTypes {
  FETCH_SERVER_STATUS_REQUEST = '@newLetrusGlobalConfiguration/FETCH_SERVER_STATUS_REQUEST',
  FETCH_SERVER_STATUS_SUCCESS = '@newLetrusGlobalConfiguration/FETCH_SERVER_STATUS_SUCCESS',
  FETCH_SERVER_STATUS_FAILURE = '@newLetrusGlobalConfiguration/FETCH_SERVER_STATUS_FAILURE',
}

export interface Status {
  ok: boolean;
  version: string;
  debug: boolean;
  uptime: string;
  started_at: string;
  hostname: string;
  current_time: string;
  current_utc_time: string;
  current_pid: number;
  current_tid: number;
}

// State type
export interface LetrusGlobalConfigurationState extends Map<string, any> {
  readonly status: ImmutableMap<Status> | undefined;
  readonly loading: boolean;
  readonly error: number | undefined;
  readonly dataCount: number;
  readonly isLoadingServerStatus: boolean;
}

// Fetch
export const fetchServerStatusRequest = () =>
  action(LetrusGlobalConfigurationTypes.FETCH_SERVER_STATUS_REQUEST);

export const fetchServerStatusSuccess = (data: any) =>
  action(LetrusGlobalConfigurationTypes.FETCH_SERVER_STATUS_SUCCESS, {data});

export const fetchServerStatusFailure = () =>
  action(LetrusGlobalConfigurationTypes.FETCH_SERVER_STATUS_FAILURE);

// Sagas
export function* fetchServerStatus(): Generator<StrictEffect, void, any> {
  try {
    const response = yield call(fetchServerStatusService);

    yield put(fetchServerStatusSuccess(response.data));
  } catch (error) {
    yield put(fetchServerStatusFailure());
  }
}

// Initial State
export const INITIAL_STATE: LetrusGlobalConfigurationState = fromJS({
  status: fromJS({}),
  loading: false,
  isLoadingServerStatus: false,
  error: false,
  dataCount: 0,
});

// Selectors
const letrusGlobalConfigurationSelector = (state: ApplicationState) =>
  state.get('globalConfiguration');

export const getStatus = createSelector(
  letrusGlobalConfigurationSelector,
  (letrusGlobalConfiguration) => letrusGlobalConfiguration.get('status'),
);

export const getCurrentTime = createSelector(
  letrusGlobalConfigurationSelector,
  (letrusGlobalConfiguration) =>
    letrusGlobalConfiguration.getIn(['status', 'current_time']),
);

export const isLoadingGlobalConfiguration = createSelector(
  letrusGlobalConfigurationSelector,
  (letrusGlobalConfiguration) => {
    return letrusGlobalConfiguration.get('loading');
  },
);

export const isLoadingServerStatus = createSelector(
  letrusGlobalConfigurationSelector,
  (letrusGlobalConfiguration) => {
    return letrusGlobalConfiguration.get('isLoadingServerStatus');
  },
);

// Reducer
const reducer: Reducer<LetrusGlobalConfigurationState | undefined> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case LetrusGlobalConfigurationTypes.FETCH_SERVER_STATUS_REQUEST: {
      return state.withMutations((prevState) =>
        prevState.set('isLoadingServerStatus', true).set('error', false),
      );
    }

    case LetrusGlobalConfigurationTypes.FETCH_SERVER_STATUS_SUCCESS: {
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingServerStatus', false)
          .set('error', false)
          .set('status', fromJS(action.payload.data))
          .set('dataCount', 1),
      );
    }

    case LetrusGlobalConfigurationTypes.FETCH_SERVER_STATUS_FAILURE: {
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingServerStatus', false)
          .set('error', true)
          .set('status', fromJS({}))
          .set('dataCount', 0),
      );
    }

    default:
      return state;
  }
};

export default reducer;
