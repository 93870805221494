import {SchoolGroup} from '@letrustech/letrus-api-interfaces/dist/interfaces/letrus_api';
import useSWR from 'swr';
import api, {GetParams} from './api';

export interface SchoolGroupExtended extends SchoolGroup {
  uses_write_preparation: string;
  available_review_types: string[];
}

export const fetchSchoolsService = () => api('/api/v1/schools/?limit=999');

export const fetchSchoolGroupsService = (params?: GetParams) =>
  api(`/api/v1/school_groups_list/`, {
    params: {
      ...params,
      current_year: true,
      limit: 1000,
    } as any,
  });

export const fetchSchoolGroupsBySchoolYearService = (
  schoolYear: number | string,
) =>
  api(`/api/v1/school_groups/?school_year=${schoolYear}`, {
    params: {
      limit: 1000,
    },
  });

export const fetchSchoolGroupsBySchoolService = (schoolId: number | string) =>
  api(`/api/v1/schools/${schoolId}/list_school_groups/`);

export interface fetchStudentListParams extends GetParams {
  schoolGroupId: number | string;
}

export const fetchStudentsBySchoolGroupService = ({
  schoolGroupId,
  limit,
  offset,
  name,
}: fetchStudentListParams) =>
  api(`/api/v1/school_groups/${schoolGroupId}/list_members/`, {
    params: {
      limit: limit ?? 1000,
      offset,
      name,
    },
  });

export const resetStudentPasswordService = (
  studentId: string | number,
  password: string,
) =>
  api(`/api/v1/users/${studentId}/reset_password_student/`, {
    method: 'post',
    data: {
      password,
    },
  });

export const fetchSchoolGroupService = () =>
  api(`/api/v1/schools/`, {
    params: {
      limit: 1000,
    },
  });

export interface ActivitiesFilter {
  school_name: string;
  school_id: number;
  school_groups: {
    school_year: number;
    school_group_id: number;
    school_group_name: string;
  }[];
}

type QueryParams = Record<string, unknown> | undefined;

export function fetchActivitiesFilterRequest(params?: QueryParams) {
  return useSWR<ActivitiesFilter[] | undefined, unknown, [string, QueryParams]>(
    [`/api/v1/activities_filter/`, params],
    ([url, params]) => api(url, {params}).then((res) => res.data.results),
    {revalidateOnFocus: false},
  );
}
