import AuthRouteMiddleware from 'components/AuthRouteMiddleware';
import KnowledgeCenter from 'containers/KnowledgeCenter';
import Privacy from 'containers/Privacy';
import SocioemotionalDashboard from 'containers/SocioEmotionalDashboard';
import {lazy} from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';

const ExternalRedirect = lazy(() => import('components/ExternalRedirect'));
const NoAuthRoute = lazy(() => import('components/NoAuthRoute'));
const PublicRoute = lazy(() => import('components/PublicRoute'));
const ScrollToTop = lazy(() => import('components/ScrollToTop'));
const Access = lazy(() => import('containers/Access'));
const CorrectionGrids = lazy(() => import('containers/CorrectionGrids'));
const EdeLetrus = lazy(
  () => import('containers/Dashboard/components/EdeLetrus'),
);
const EvolutionReport = lazy(() => import('containers/EvolutionReport'));
const PrintPage = lazy(() => import('containers/EvolutionReport/PrintPage'));
const LearningPathReport = lazy(() => import('containers/LearningPathReport'));
const FinishedReport = lazy(() => import('containers/FinishedReport'));
const GradeUnique = lazy(() => import('containers/GradeUnique'));
const Homepage = lazy(() => import('containers/Homepage'));
const Instructions = lazy(() => import('containers/Instructions'));
const LiveReport = lazy(() => import('containers/LiveReport'));
const Proposal = lazy(() => import('containers/LiveReport/Proposal'));
const Login = lazy(() => import('containers/Login'));
const NotFound = lazy(() => import('containers/NotFound'));
const PreLetrus = lazy(() => import('containers/PreLetrus'));
const ProfilePage = lazy(() => import('containers/ProfilePage'));
const Questionnaire = lazy(() => import('containers/Questionnaire'));
const ResetPassword = lazy(() => import('containers/ResetPassword'));
const ResetForm = lazy(() => import('containers/ResetPassword/ResetForm'));
const Restricted = lazy(() => import('containers/Restricted'));
const Review = lazy(() => import('containers/Review'));
const ReviewedComposition = lazy(
  () => import('containers/ReviewedComposition'),
);
const SchoolGroupPerformance = lazy(
  () => import('containers/SchoolGroupPerformance'),
);
const Signup = lazy(() => import('containers/Signup'));
const TeacherGradeInfo = lazy(() => import('containers/TeacherGradeInfo'));
const TeacherLiveTest = lazy(() => import('containers/TeacherLiveTest'));
const TeacherNotifications = lazy(() => import('containers/Notifications'));
const TeacherStudentInfo = lazy(() => import('containers/TeacherStudentInfo'));
const TeacherStudentInfoChangePassword = lazy(
  () =>
    import('containers/TeacherStudentInfo/TeacherStudentInfoChangePassword'),
);
const StudentsList = lazy(() => import('containers/StudentsList'));
const Dashboard = lazy(() => import('containers/Dashboard'));
const TeacherTestsScheduling = lazy(
  () => import('containers/TeacherTestsScheduling'),
);
const TeacherTheme = lazy(() => import('containers/TeacherTheme'));
const Terms = lazy(() => import('containers/Terms'));
const TestFinishedPage = lazy(() => import('containers/TestFinishedPage'));
const TestsListPage = lazy(() => import('containers/TestsListPage'));
const ThemesList = lazy(() => import('containers/ThemesList'));
const Unauthorized = lazy(() => import('containers/Unauthorized'));
const UnderConstruction = lazy(() => import('containers/UnderConstruction'));
const FirstAccess = lazy(() => import('containers/FirstAccess'));
const TestFinishedPrintPage = lazy(
  () => import('containers/TestFinishedPrintPage'),
);

export enum AuthRoutes {
  reviewGrid = '/grades/:review_grid_id',
  reviewGrids = '/grades',
  evolutionReport = '/evolucao',
  knowledgeCenter = '/centraldeconhecimento',
  LearningPathReport = '/atividades/:learning_path_type/unificado/:learning_path_instance_id/:learning_path_state/',
  finishedReport = '/terminada/:test_id',
  fundLiveTest = '/atividades/:test_type/aovivo/fund/:test_id',
  liveTest = '/atividades/:test_type/aovivo/:test_id',
  notifications = '/notificacoes',
  printPage = '/evolucao/impressao',
  profile = '/perfil',
  instructions = '/proposta/:test_id',
  proposal = '/atividades/:test_type/aovivo/:test_id/proposta',
  restrictedPage = '/restricted',
  review = '/correcao/:composition_id',
  reviewedComposition = '/corrigida/:composition_id',
  studentsList = '/turmas',
  schoolGroupPerformance = '/atividades/:test_type/terminada/:test_id/fund',
  studentInfo = '/turmas/estudante/:student_id',
  studentInfoChangePassword = '/turmas/estudante/:student_id/alterar_senha',
  testsScheduling = '/nova-atividade',
  testFinishedPage = '/atividades/:test_type/terminada/:test_id',
  testFinishedPrintPage = '/atividades/:test_type/terminada/:test_id/impressao',
  dashboard = '/',
  testsListPage = '/atividades/:test_type',
  theme = '/temas/:theme_id',
  themes = '/temas',
  uniqueGrid = '/grade-unica',
  acceptTerms = '/aceite-termos',
  ede = '/intro_ede',
  pre = '/intro_pre',
  underConstruction = '/em-construcao',
  library = '/biblioteca',
  socioEmotionalDashboard = '/socio_emocional',
  firstAccess = '/primeiro-acesso',
}

export enum PublicRoutes {
  login = '/login',
  logout = '/logout',
  notFound = '/not-found',
  resetPassword = '/redefinir_senha',
  resetForm = '/reset/:uidb64/:token',
  signup = '/signup',
  questionnaire = '/pesquisa/:questionnaire_id',
  unauthorized = '/unauthorized',
  examples = '/aulas/exemplos',
  agents = '/aulas/agentes',
  oralities = '/aulas/oralidades',
  senteces = '/aulas/sentencas',
  ai = '/aulas/ia',
  access = '/acesso',
  privacy = '/privacidade',
}

function Router(): JSX.Element {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Switch>
        <AuthRouteMiddleware
          withPageWrapper
          exact
          path={AuthRoutes.library}
          Component={() => (
            <iframe
              width="100%"
              height="100%"
              style={{width: '100%'}}
              src="https://letrus-com-br-7068977.hs-sites.com/conteudos-letrus-mt-2"
              title="biblioteca letrus"
            />
          )}
        />
        <AuthRouteMiddleware
          withPageWrapper
          exact
          path={AuthRoutes.socioEmotionalDashboard}
          Component={SocioemotionalDashboard}
        />
        <AuthRouteMiddleware
          withPageWrapper
          exact
          path={AuthRoutes.knowledgeCenter}
          Component={KnowledgeCenter}
        />
        <AuthRouteMiddleware
          withPageWrapper
          exact
          path={AuthRoutes.restrictedPage}
          Component={Restricted}
        />
        <AuthRouteMiddleware
          withPageWrapper
          exact
          path={AuthRoutes.theme}
          Component={TeacherTheme}
        />
        <AuthRouteMiddleware
          withPageWrapper
          exact
          path={AuthRoutes.reviewGrids}
          Component={CorrectionGrids}
        />
        <AuthRouteMiddleware
          withPageWrapper
          exact
          path={AuthRoutes.studentsList}
          Component={StudentsList}
        />
        <AuthRouteMiddleware
          withPageWrapper
          exact
          path={AuthRoutes.themes}
          Component={ThemesList}
        />
        <AuthRouteMiddleware
          withPageWrapper
          exact
          path={AuthRoutes.dashboard}
          Component={Dashboard}
        />
        <AuthRouteMiddleware
          withPageWrapper
          exact
          path={AuthRoutes.reviewGrid}
          Component={TeacherGradeInfo}
        />
        <AuthRouteMiddleware
          withPageWrapper
          exact
          path={AuthRoutes.uniqueGrid}
          Component={GradeUnique}
        />
        <AuthRouteMiddleware
          withPageWrapper
          exact
          path={AuthRoutes.evolutionReport}
          Component={EvolutionReport}
        />
        <AuthRouteMiddleware
          withPageWrapper
          exact
          path={AuthRoutes.studentInfo}
          Component={TeacherStudentInfo}
        />
        <AuthRouteMiddleware
          withPageWrapper
          exact
          path={AuthRoutes.studentInfoChangePassword}
          Component={TeacherStudentInfoChangePassword}
        />
        <AuthRouteMiddleware
          withPageWrapper
          exact
          path={AuthRoutes.testsListPage}
          Component={TestsListPage}
        />
        <AuthRouteMiddleware
          withPageWrapper
          exact
          path={AuthRoutes.testFinishedPage}
          Component={TestFinishedPage}
        />
        <AuthRouteMiddleware
          exact
          path={AuthRoutes.testFinishedPrintPage}
          Component={TestFinishedPrintPage}
        />
        <AuthRouteMiddleware
          withPageWrapper
          exact
          path={AuthRoutes.liveTest}
          Component={TeacherLiveTest}
        />
        <AuthRouteMiddleware
          withPageWrapper
          exact
          path={AuthRoutes.notifications}
          Component={TeacherNotifications}
        />
        <AuthRouteMiddleware
          exact
          path={AuthRoutes.printPage}
          Component={PrintPage}
        />
        <AuthRouteMiddleware
          withPageWrapper
          exact
          path={AuthRoutes.profile}
          Component={ProfilePage}
        />
        <AuthRouteMiddleware
          withPageWrapper
          exact
          path={AuthRoutes.fundLiveTest}
          Component={LiveReport}
        />
        <AuthRouteMiddleware
          withPageWrapper
          exact
          path={AuthRoutes.instructions}
          Component={Instructions}
        />
        <AuthRouteMiddleware
          withPageWrapper
          exact
          path={AuthRoutes.proposal}
          Component={Proposal}
        />
        <AuthRouteMiddleware
          withPageWrapper
          exact
          path={AuthRoutes.testsScheduling}
          Component={TeacherTestsScheduling}
        />
        <AuthRouteMiddleware
          withPageWrapper
          exact
          path={AuthRoutes.reviewedComposition}
          Component={ReviewedComposition}
        />
        <AuthRouteMiddleware
          withPageWrapper
          exact
          path={AuthRoutes.finishedReport}
          Component={FinishedReport}
        />
        <AuthRouteMiddleware
          withPageWrapper
          exact
          path={AuthRoutes.schoolGroupPerformance}
          Component={SchoolGroupPerformance}
        />
        <AuthRouteMiddleware
          withPageWrapper
          exact
          path={AuthRoutes.review}
          Component={Review}
        />
        <AuthRouteMiddleware
          exact
          path={AuthRoutes.acceptTerms}
          Component={Terms}
        />
        <AuthRouteMiddleware
          exact
          withPageWrapper
          path={AuthRoutes.LearningPathReport}
          Component={LearningPathReport}
        />
        <AuthRouteMiddleware path={AuthRoutes.ede} Component={EdeLetrus} />
        <AuthRouteMiddleware path={AuthRoutes.pre} Component={PreLetrus} />
        <AuthRouteMiddleware
          exact
          withPageWrapper
          path={AuthRoutes.underConstruction}
          Component={UnderConstruction}
        />
        <AuthRouteMiddleware
          path={AuthRoutes.firstAccess}
          Component={FirstAccess}
        />
        <PublicRoute path={PublicRoutes.logout} Component={Homepage} />
        <PublicRoute
          path={PublicRoutes.unauthorized}
          Component={Unauthorized}
        />
        <PublicRoute path={PublicRoutes.notFound} Component={NotFound} />
        <PublicRoute path={PublicRoutes.login} Component={Login} />
        <PublicRoute path={PublicRoutes.signup} Component={Signup} />
        <PublicRoute exact path={PublicRoutes.access} Component={Access} />
        <PublicRoute
          path={PublicRoutes.resetPassword}
          Component={ResetPassword}
        />
        <PublicRoute path={PublicRoutes.resetForm} Component={ResetForm} />
        <PublicRoute
          path={PublicRoutes.questionnaire}
          Component={Questionnaire}
        />
        <PublicRoute exact path={PublicRoutes.privacy} Component={Privacy} />
        <Route
          path={PublicRoutes.examples}
          component={() => (
            <ExternalRedirect to="https://xd.adobe.com/view/082c7ab8-be4e-4a9d-b9a6-81995c6cc48c/?fullscreen&hints=off" />
          )}
        />
        <Route
          path={PublicRoutes.agents}
          component={() => (
            <ExternalRedirect to="https://xd.adobe.com/view/443fb525-bf91-4b40-9d77-83b356432547/?fullscreen&hints=off" />
          )}
        />
        <Route
          path={PublicRoutes.oralities}
          component={() => (
            <ExternalRedirect to="https://xd.adobe.com/view/f5a23476-c5a2-4e6b-bdbf-59f2b9ab27e0?fullscreen&hints=off" />
          )}
        />
        <Route
          path={PublicRoutes.senteces}
          component={() => (
            <ExternalRedirect to="https://xd.adobe.com/view/fe364a38-1626-4a1c-a1cc-61d96c0c7d93?fullscreen&hints=off" />
          )}
        />
        <Route
          path={PublicRoutes.ai}
          component={() => (
            <ExternalRedirect to="https://xd.adobe.com/view/3da38adc-bd61-42c6-8ca6-88856bc74516/?fullscreen&hints=off" />
          )}
        />
        <NoAuthRoute />
      </Switch>
    </BrowserRouter>
  );
}

export default Router;
