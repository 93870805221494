import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {LetrusApi} from '@letrustech/letrus-api-interfaces';
import AvatarPlaceholder from 'assets/images/avatar_placeholder.png';
import {Button} from 'letrus-ui';
import {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {
  clearAuthenticationStore,
  logoutUserRequest,
} from 'store/reducers/authentication';
import {getUser} from 'store/reducers/user';
import {ApplicationState} from 'store/rootReducer';
import {useMobile} from 'utils/hooks';
import {clearAllCookies} from 'utils/types/cookies';
import styles from './DropdownAccount.module.scss';

interface StateProps {
  user: ImmutableMap<LetrusApi.User>;
}

interface DispatchProps {
  logoutUserRequest: typeof logoutUserRequest;
  clearAuthenticationStore: typeof clearAuthenticationStore;
}

type DropdownAccountProps = DispatchProps & StateProps;

function DropdownAccount({
  user,
  logoutUserRequest,
  clearAuthenticationStore,
}: DropdownAccountProps): JSX.Element {
  const [listOpen, setListOpen] = useState(false);
  const {isMobile} = useMobile();

  function onClickLogout(event: React.MouseEvent) {
    event.preventDefault();
    logoutUserRequest();
    clearAuthenticationStore();
    localStorage.clear();
    sessionStorage.clear();
    clearAllCookies();
  }

  const dropdownItems = [
    {
      id: 1,
      title: 'Configurações',
      url: '/perfil',
      icon: ['fas', 'cog'] as IconProp,
    },
    {
      id: 2,
      children: (
        <a href="/logout_and_go" onClick={onClickLogout}>
          <FontAwesomeIcon icon="sign-out-alt" />
          Sair
        </a>
      ),
    },
  ];

  function onClickOutside() {
    if (listOpen) {
      setListOpen(false);
    }
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (event.target.id !== 'DropdownAccount') {
        onClickOutside();
      }
    }

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [onClickOutside]);

  function toggleList() {
    setListOpen(!listOpen);
  }

  return (
    <div
      className={styles.dropdownAccount}
      data-testid="dropdown-account"
      data-ref-id="DropdownAccount"
    >
      <Button
        variant="tertiary"
        text={isMobile ? '' : 'Minha Conta'}
        rightIcon={isMobile ? {icon: 'user-circle'} : {icon: 'chevron-down'}}
        onClick={toggleList}
      />

      {/* TODO: this dropdown component will be changed for a new design */}
      {listOpen && (
        <div className={styles.list}>
          <div>
            <img
              className={styles.avatar}
              src={AvatarPlaceholder}
              alt="profile"
            />

            <h3>{`${user.get('first_name')} ${user.get('last_name')}`}</h3>

            <span>{user.get('email')}</span>
          </div>
          <ul>
            {dropdownItems?.map((item) => (
              <li className={styles.item} key={item.id}>
                {item.children ? (
                  item.children
                ) : (
                  <Link to={item.url}>
                    <FontAwesomeIcon icon={item.icon} />
                    {item.title}
                  </Link>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default connect(
  (state: ApplicationState) => ({
    user: getUser(state),
  }),
  {
    logoutUserRequest,
    clearAuthenticationStore,
  },
)(DropdownAccount);
