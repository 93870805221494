import {LetrusApi} from '@letrustech/letrus-api-interfaces';
import {ReactComponent as LetrusBrand} from 'assets/images/logoLetrus.svg';
import ExternalRedirect from 'components/ExternalRedirect';
import Menu from 'components/Menu';
import {PageWrapper} from 'letrus-ui';
import {useEffect} from 'react';
import {connect} from 'react-redux';
import {
  Redirect,
  Route,
  RouteComponentProps,
  useHistory,
  useLocation,
} from 'react-router-dom';
import {PublicRoutes} from 'routes';
import {
  clearAuthenticationStore,
  isAdmin,
  isAuthed,
  logoutUserRequest,
} from 'store/reducers/authentication';
import {
  fetchFeatureFlagRequest,
  getFeatureFlags,
} from 'store/reducers/featureFlags';
import {
  fetchNotificationsRequest,
  fetchSidebarNotificationsRequest,
  getNotifications,
  getSidebarNotification,
  getUnseenNotificationCount,
  SidebarNotification,
} from 'store/reducers/notifications';
import {
  fetchCurrentUserRequest,
  getUser,
  getUserRoles,
  isLoadingCurrentUser,
  isReviewer,
  isTeacher,
} from 'store/reducers/user';
import {ApplicationState} from 'store/rootReducer';
import setupUserHubspotConversation from 'utils/functions/hubspot/setupUserHubspotConversation';
import isImmutableObjectEmpty from 'utils/functions/isImmutableObjectEmpty';
import {useMobile} from 'utils/hooks';
import useTrackEvent from 'utils/hooks/useTrackEvent';
import {clearAllCookies, getCookie} from 'utils/types/cookies';
import {ExtendedUserRole} from 'utils/types/users';

export type UserRole = 'admin' | 'staff' | 'nonAdmin';

type FeatureFlags = {
  [K in keyof any]?: 'on' | 'off';
};

interface OwnProps {
  Component: React.FC<any>;
  path: string;
  exact?: boolean;
  withPageWrapper?: boolean;
}

interface StateProps {
  isDevAuthStateValid: boolean;
  isAdmin: boolean;
  isTeacher: boolean;
  isReviewer: boolean;
  user: ImmutableMap<LetrusApi.User>;
  isLoadingCurrentUser: boolean;
  unseenNotificationCount: any;
  notifications: any;
  featureFlags?: ImmutableMap<FeatureFlags>;
  userRole: ImmutableMap<ExtendedUserRole>;
  sidebarNotification?: SidebarNotification;
}

interface DispatchProps {
  fetchCurrentUserRequest: typeof fetchCurrentUserRequest;
  logoutUserRequest: typeof logoutUserRequest;
  clearAuthenticationStore: typeof clearAuthenticationStore;
  fetchNotificationsRequest: typeof fetchNotificationsRequest;
  fetchFeatureFlagRequest: typeof fetchFeatureFlagRequest;
  fetchSidebarNotificationsRequest: typeof fetchSidebarNotificationsRequest;
}

type AuthRouteProps = OwnProps & StateProps & DispatchProps;

function AuthRouteMiddleware({
  Component,
  path,
  exact = false,
  isDevAuthStateValid,
  user,
  fetchCurrentUserRequest,
  isLoadingCurrentUser,
  isReviewer,
  unseenNotificationCount,
  notifications,
  withPageWrapper,
  clearAuthenticationStore,
  fetchNotificationsRequest,
  fetchFeatureFlagRequest,
  featureFlags,
  userRole,
  sidebarNotification,
  fetchSidebarNotificationsRequest,
}: AuthRouteProps): JSX.Element {
  const history = useHistory();
  const {isMobile} = useMobile();
  const {trackEvent} = useTrackEvent();

  const shouldShowLibraryOnMenu = featureFlags?.get('teacher_library') === 'on';
  const shouldShowSocioEmotionalOnMenu =
    featureFlags?.get('teacher_socioemotional_dashboard') === 'on';
  const isSidebarNotificationActive =
    featureFlags?.get('learning_path_notification') === 'on';

  const {pathname} = useLocation();

  const authToken = getCookie('userId');
  const isProdEnviroment = process.env.NODE_ENV === 'production';
  const isDevEnviroment = process.env.NODE_ENV !== 'production';

  const isAuthenticated =
    (isProdEnviroment && authToken) ||
    (isDevEnviroment && (isDevAuthStateValid || authToken));

  useEffect(() => {
    if (isAuthenticated) {
      fetchNotificationsRequest();
    }
  }, [pathname]);

  useEffect(() => {
    if (isAuthenticated && isSidebarNotificationActive) {
      fetchSidebarNotificationsRequest();
    }
  }, [pathname, isSidebarNotificationActive]);

  useEffect(() => {
    if (isAuthenticated) {
      const unseenNotificationText = `(${
        unseenNotificationCount > 99 ? '99+' : unseenNotificationCount
      })`;

      document.title = `${
        unseenNotificationCount ? unseenNotificationText : ''
      } Letrus`;
    }
  }, [pathname, notifications]);

  useEffect(() => {
    if (
      !isLoadingCurrentUser &&
      !isImmutableObjectEmpty(user) &&
      process.env.NODE_ENV === 'production'
    ) {
      // If external API methods are already available, use them.
      if (window.HubSpotConversations) {
        setupUserHubspotConversation(user);
      } else {
        // Otherwise, callbacks can be added to the hsConversationsOnReady on the window object.
        // These callbacks will be called once the external API has been initialized.
        window.hsConversationsOnReady = [
          () => setupUserHubspotConversation(user),
        ];
      }
    }
  }, [isLoadingCurrentUser]);

  if (window.hsConversationsSettings) {
    window.hsConversationsSettings = {
      loadImmediately: false,
    };
  }

  useEffect(() => {
    if (isAuthenticated) {
      fetchCurrentUserRequest();

      const schoolNetworkId = userRole?.get('school_network_id');

      fetchFeatureFlagRequest({
        flagKey: 'teacher_library',
        entityId: `${user.get('id')}`,
        entityContext: {
          network_id: String(schoolNetworkId),
        },
      });

      fetchFeatureFlagRequest({
        flagKey: 'teacher_socioemotional_dashboard',
        entityId: `${user.get('id')}`,
        entityContext: {
          network_id: String(schoolNetworkId),
        },
      });

      fetchFeatureFlagRequest({
        flagKey: 'learning_path_notification',
        entityId: `${user.get('id')}`,
        entityContext: {
          isDev: isDevEnviroment,
          school_id: String(userRole?.get('school')),
        },
      });
    }
  }, []);

  function clearBrowserData() {
    clearAuthenticationStore();
    clearAllCookies();
    sessionStorage.clear();
  }

  // checks if the user already logged is not a teacher or admin, and then redirects them to a specific URL based on their role.
  if (user.size && !isTeacher && !isAdmin) {
    let urlToRedirect: string;

    if (isReviewer) {
      urlToRedirect = 'https://corretor.letrus.com.br';
    }
    urlToRedirect = 'https://aluno.letrus.com.br';

    return <ExternalRedirect to={urlToRedirect} />;
  }

  function mountNewSidebarItems(
    label: string,
    icon: [string, string],
    active: boolean,
    onClick: () => void,
    notificationCount?: number,
  ) {
    return {
      label,
      icon: {icon},
      active,
      onClick,
      notificationCount: notificationCount || undefined,
    };
  }

  function onClickLearningPath() {
    trackEvent('sidebar_click_learning_path');
    history.push('/atividades/regulares');
  }

  const topSidebarItems = [
    mountNewSidebarItems(
      'Página Inicial',
      ['fad', 'house-chimney'],
      pathname === '/',
      () => history.push('/'),
    ),
    mountNewSidebarItems(
      'Trilhas',
      ['fad', 'train-track'],
      pathname === '/atividades/regulares',
      onClickLearningPath,
      sidebarNotification?.lpi_not_seen_by_teacher,
    ),
    mountNewSidebarItems(
      'Evolução',
      ['fad', 'chart-line'],
      pathname === '/evolucao',
      () => history.push(`/evolucao`),
    ),
    mountNewSidebarItems(
      'Turmas',
      ['fad', 'graduation-cap'],
      pathname === '/turmas',
      () => history.push(`/turmas`),
    ),
    mountNewSidebarItems(
      'Temas',
      ['fad', 'clipboard-list'],
      pathname === `/temas`,
      () => history.push(`/temas`),
    ),
    mountNewSidebarItems(
      'Grades',
      ['fad', 'clipboard-check'],
      pathname === '/grades',
      () => history.push(`/grades`),
    ),
    ...(shouldShowLibraryOnMenu
      ? [
          mountNewSidebarItems(
            'Biblioteca',
            ['fad', 'book-open'],
            pathname === '/biblioteca',
            () => history.push(`/biblioteca`),
          ),
        ]
      : []),
    ...(shouldShowSocioEmotionalOnMenu
      ? [
          mountNewSidebarItems(
            'Emocional',
            ['fad', 'face-smile-beam'],
            pathname === '/socio_emocional',
            () => history.push(`/socio_emocional`),
          ),
        ]
      : []),
    mountNewSidebarItems(
      'FAQ',
      ['fad', 'question-circle'],
      pathname === '/centraldeconhecimento',
      () => history.push(`/centraldeconhecimento`),
    ),
  ];

  const route = (
    <Route
      exact={exact}
      path={path}
      render={(props: RouteComponentProps) => {
        return <Component {...props} />;
      }}
    />
  );

  if (isAuthenticated && withPageWrapper) {
    return (
      <PageWrapper
        topMenu={topSidebarItems}
        isMenuButtonDisabled={!isMobile}
        theme="dark"
        centerComponent={
          <>
            {userRole?.get('network_logo') && (
              <img src={userRole.get('network_logo')} alt="logo da rede" />
            )}
            <LetrusBrand height="3rem" width="5rem" />
          </>
        }
        rightComponent={<Menu />}
        userRole="teacher"
      >
        {route}
      </PageWrapper>
    );
  }

  if (isAuthenticated && !withPageWrapper) {
    return route;
  }

  // Logs out the user and redirect to login
  clearBrowserData();
  return <Redirect to={{pathname: PublicRoutes.login}} />;
}

export default connect(
  (state: ApplicationState) => ({
    isDevAuthStateValid: isAuthed(state),
    isTeacher: isTeacher(state),
    isReviewer: isReviewer(state),
    isAdmin: isAdmin(state),
    user: getUser(state),
    userRole: getUserRoles(state).first(),
    isLoadingCurrentUser: isLoadingCurrentUser(state),
    unseenNotificationCount: getUnseenNotificationCount(state),
    notifications: getNotifications(state),
    featureFlags: getFeatureFlags(state),
    sidebarNotification: getSidebarNotification(state),
  }),
  {
    fetchCurrentUserRequest,
    logoutUserRequest,
    clearAuthenticationStore,
    fetchNotificationsRequest,
    fetchFeatureFlagRequest,
    fetchSidebarNotificationsRequest,
  },
)(AuthRouteMiddleware);
