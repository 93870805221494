import {Map} from 'immutable';
import {reducer as formReducer} from 'redux-form/immutable';
import {combineReducers} from 'redux-immutable';
import abandonReviewsReducer, {
  AbandonReviewReasonsState,
} from './reducers/abandonReview';
import authenticationReducer, {
  AuthenticationState,
} from './reducers/authentication';
import compositionReviewsReducer, {
  CompositionReviewsState,
} from './reducers/compositionReviews';
import compositionsReducer, {CompositionsState} from './reducers/compositions';
import featureFlagsReducer, {FeatureFlagsState} from './reducers/featureFlags';
import generalAnnotationsReducer, {
  GeneralAnnotationsState,
} from './reducers/generalAnnotations';
import globalConfigurationReducer, {
  LetrusGlobalConfigurationState,
} from './reducers/globalConfiguration';
import knowledgeLearningPathsReducer, {
  KnowledgeLearningPathsState,
} from './reducers/knowledgeLearningPaths';
import learningPathsReducer, {
  LearningPathsState,
} from './reducers/learningPaths';
import notificationsReducer, {
  NotificationsState,
} from './reducers/notifications';
import questionnairesReducer, {
  QuestionnairesState,
} from './reducers/questionnaires';
import reviewAnnotationsReducer, {
  ReviewAnnotationsState,
} from './reducers/reviewAnnotations';
import reviewGridsReducer, {ReviewGridsState} from './reducers/reviewGrids';
import schoolGroupsReducer, {SchoolGroupsState} from './reducers/schoolGroups';
import studentParentsReducer, {
  StudentParentsState,
} from './reducers/studentParents';
import studentsReducer, {StudentsState} from './reducers/students';
import termsReducer, {TermsState} from './reducers/terms';
import testsReducer, {TestsState} from './reducers/tests';
import themesReducer, {ThemesState} from './reducers/themes';
import uiReducer, {UIState} from './reducers/ui';
import userReducer, {UserState} from './reducers/user';
import zeroGradeCommentsReducer, {
  ZeroGradeCommentsState,
} from './reducers/zeroGradeComments';

export interface ApplicationState extends Map<any, any> {
  readonly zeroGradeComments: ZeroGradeCommentsState;
  readonly abandonReviews: AbandonReviewReasonsState;
  readonly authentication: AuthenticationState;
  readonly compositions: CompositionsState;
  readonly compositionReviews: CompositionReviewsState;
  readonly featureFlags: FeatureFlagsState;
  readonly generalAnnotations: GeneralAnnotationsState;
  readonly globalConfiguration: LetrusGlobalConfigurationState;
  readonly learningPaths: LearningPathsState;
  readonly reviewAnnotations: ReviewAnnotationsState;
  readonly schoolGroups: SchoolGroupsState;
  readonly studentParents: StudentParentsState;
  readonly themes: ThemesState;
  readonly tests: TestsState;
  readonly notifications: NotificationsState;
  readonly questionnaires: QuestionnairesState;
  readonly user: UserState;
  readonly ui: UIState;
  readonly knowledgeLearningPaths: KnowledgeLearningPathsState;
  readonly students: StudentsState;
  readonly reviewGrids: ReviewGridsState;
  readonly terms: TermsState;
}

const rootReducer = () =>
  combineReducers({
    abandonReviews: abandonReviewsReducer,
    authentication: authenticationReducer,
    compositions: compositionsReducer,
    compositionReviews: compositionReviewsReducer,
    featureFlags: featureFlagsReducer,
    form: formReducer,
    generalAnnotations: generalAnnotationsReducer,
    globalConfiguration: globalConfigurationReducer,
    learningPaths: learningPathsReducer,
    reviewAnnotations: reviewAnnotationsReducer,
    studentParents: studentParentsReducer,
    schoolGroups: schoolGroupsReducer,
    themes: themesReducer,
    tests: testsReducer,
    notifications: notificationsReducer,
    questionnaires: questionnairesReducer,
    ui: uiReducer,
    user: userReducer,
    zeroGradeComments: zeroGradeCommentsReducer,
    knowledgeLearningPaths: knowledgeLearningPathsReducer,
    students: studentsReducer,
    reviewGrids: reviewGridsReducer,
    terms: termsReducer,
  });

export default rootReducer;
